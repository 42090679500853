import { FC, useState } from 'react'
import { Modal, Button } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import TransactionLines from '../../../pages/TRDTransactionLines'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useGlobalStore } from '../../../global/global-store'
import useTxnEnableFeatureStore from '../../../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'

const UnpostedInvoiceModal: FC<any> = ({
  showModal,
  data,
  onCancel,
  vendorName,
  isReportingCurrency,
  currencyName
}): JSX.Element => {
  const { consolidatedTxnView } = useTxnEnableFeatureStore()
  const [size] = useState<SizeType>('large')
  const { activeKey, setActiveKey, display, setModalDelay } = useGlobalStore()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Details`,
      children: <TransactionLines />
    },
    {
      key: '2',
      label: `History`,
      children: display
    }
  ]

  const items_consolidated: TabsProps['items'] = [
    {
      key: '1',
      label: `Details`,
      children: (
        <TransactionLines
          hasTransactionPanel={false}
          // hasActionColumns={false}
          hasSelectAll={false}
          isExpandable={false}
          hasNetExpenseAmount
        />
      )
    },
    {
      key: '2',
      label: `History`,
      children: display
    }
  ]

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    setModalDelay(true)
  }

  return (
    <Modal
      data-cy='rc-unposted-invoice-modal'
      data-testid='rc-unposted-invoice-modal'
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '-10px'
          }}
          data-cy='rc-unposted-invoice-modal-title'
          data-testid='rc-unposted-invoice-modal-title'
        >
          <h2>{vendorName}</h2>
        </div>
      }
      open={showModal}
      onCancel={onCancel}
      width={1500}
      bodyStyle={{ height: 'auto', padding: '0 20px' }}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right'
          }}
        >
          <Button
            data-cy='rc-unposted-invoice-modal-close-btn'
            data-testid='rc-unposted-invoice-modal-close-btn'
            shape='round'
            key='Close'
            type='primary'
            onClick={onCancel}
            size={size}
          >
            &nbsp; &nbsp; Close &nbsp; &nbsp;
          </Button>
        </div>
      }
    >
      {
        <Tabs
          activeKey={activeKey}
          items={consolidatedTxnView ? items_consolidated : items}
          onChange={handleTabChange}
        />
      }
    </Modal>
  )
}
export default UnpostedInvoiceModal
