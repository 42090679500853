import { useMutation } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = {
  invoice_statuses: string[]
  po_line_invoice_amount_includes_unapproved_invoices: boolean
}

type Params = {
  invoice_statuses: string[]
  po_line_invoice_amount_includes_unapproved_invoices: boolean
  // Add other fields here
}

type Error = {
  data: {
    errors: {
      invoice_statuses: string[]
      po_line_invoice_amount_includes_unapproved_invoices: string[]
      // Add other fields here
    }
    message: string
  }
}

const useSubmitUnapprovedInvoices = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useMutation<Response, Error, Params>({
    mutationFn: (payload) =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/settings/unposted-invoices`,
          method: 'PUT'
        },
        data: payload
      })
  })
}

export default useSubmitUnapprovedInvoices
