import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd'
import { useGlobalStore } from '../../../global/global-store'
import { getTrdTransactions } from '../../../services/TrdManagerAPI'
import { formatNumberWithComma } from '../../../pages/AccrualAnalytics/helper/formatNumber'
import TransactionLine from '../../../types/TransactionLine'
import '../styles/styles.css'
import GIcon from '../../gappify/GIcon'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface NetExpenseModalProps {
  row: any
}

const NetExpenseModal: React.FC<NetExpenseModalProps> = ({ row }) => {
  const {
    subsidiaryIdParams,
    departmentIdParams,
    glaccountIdParams,
    vendorIdParams,
    vendorNameParams,
    periodParams,
    keyParams,
    viewParams,
    typeParams
  } = useGlobalStore()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [data, setData] = useState([])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const netExpenseParams = {
    subsidiary_id: subsidiaryIdParams,
    department_id: departmentIdParams,
    glaccount_id: glaccountIdParams,
    vendor_id: vendorIdParams,
    vendor_name: vendorNameParams,
    type: typeParams,
    key: keyParams,
    period: periodParams,
    view: viewParams,
    accrual_source: row.g_accrual_source,
    line_id: row.id
  }

  const encodedNetExpenseParams = btoa(encodeURIComponent(JSON.stringify(netExpenseParams)))

  const columns = [
    {
      title: 'PO Number',
      dataIndex: 'po_number',
      key: 'po_number'
    },
    {
      title: 'PO Description',
      dataIndex: 'po_description',
      key: 'po_description'
    },
    {
      title: 'Subsidiary',
      dataIndex: 'subsidiary',
      key: 'subsidiary'
    },
    {
      title: 'GL Account',
      dataIndex: 'glaccount',
      key: 'glaccount'
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department'
    },
    {
      title: `Amount (in ${data && (data[0] as any)?.currency})`,
      dataIndex: 'subsidiary_amount',
      key: 'subsidiary_amount',
      render: (amount: string) => amount && formatNumberWithComma(amount),
      align: 'right'
    }
  ]

  const fetchData = async () => {
    setIsLoadingData(true)
    try {
      let { data } = await getTrdTransactions({
        rc_net_expense_params: encodedNetExpenseParams
      })
      setData(data)
      setIsLoadingData(false)
    } catch (error) {
      // Handle errors if necessary
      console.error('Error fetching data:', error)
      setIsLoadingData(false)
    }
  }

  const rowClassName = (record: TransactionLine) => {
    return record.is_included === 0 ? 'grey-row' : ''
  }

  return (
    <>
      <div
        role='button'
        onClick={() => {
          if (!row.isTotalNetExpenseAmount) {
            setData([])
            showModal()
            fetchData()
          }
        }}
        style={{
          color: row.isTotalNetExpenseAmount ? 'auto' : '#725bb4',
          cursor: row.isTotalNetExpenseAmount ? 'auto' : 'pointer',
          alignContent: 'end',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <strong>{row.isTotalNetExpenseAmount && 'Total'}</strong>
        <div
          style={{
            textDecoration: row.isTotalNetExpenseAmount ? 'auto' : 'underline'
          }}
        >
          {formatNumberWithComma(row.net_expense_amount)}
        </div>
      </div>
      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '-10px'
            }}
            data-cy='rc-quick-actions-modal-title'
            data-testid='rc-quick-actions-modal-title'
          >
            <h2>{row.g_name as string}</h2>
          </div>
        }
        width={1500}
        bodyStyle={{ height: 'auto', padding: '0 20px', position: 'relative' }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right'
            }}
          >
            <Button
              data-cy='rc-calculated-accrual-modal-close-btn'
              data-testid='rc-calculated-accrual-modal-close-btn'
              shape='round'
              key='Close'
              type='primary'
              onClick={handleCancel}
              size='large'
            >
              &nbsp; &nbsp; Close &nbsp; &nbsp;
            </Button>
          </div>
        }
      >
        <div
          style={{ marginTop: '15px', marginBottom: '15px', fontSize: '12px' }}
        >
          <GIcon icon={faInfoCircle} /> Rows greyed-out below refer to lines
          that do not match your drill-down criteria. These lines are exposed in
          this view to show users the opposite side of the selected transaction
          entry.
        </div>

        <Table
          loading={isLoadingData}
          dataSource={data}
          columns={columns as any}
          pagination={false}
          footer={() => (
            <div style={{ display: 'flex', float: 'right', gap: 30 }}>
              <strong>Net Expense Amount</strong>
              {formatNumberWithComma(row.net_expense_amount)}
            </div>
          )}
          rowClassName={rowClassName}
        />
      </Modal>
    </>
  )
}

export default NetExpenseModal
