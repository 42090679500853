/** React Imports */
import { useEffect, useRef } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'

/** AntD Imports */
import { Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

/** Hooks */
import useSettingsStore from '../../manager/settings-store'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import useUnapprovedInvoicesStore from './components/UnapprovedInvoices/manager/unapproved-invoices-store'

/** Components */
import AccrualManager from './components/AccrualManager'
import UnapprovedInvoices from './components/UnapprovedInvoices'
import CompanySettingsTitle from './components/CompanySettingsTitle'

/** Constants */
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

/** Styles */
import styles from './index_q3.module.css'

/** Utils */
import isGappifyAdministrator from '../../utils/isGappifyAdministrator'
import isSystemAdministrator from '../../utils/isSystemAdministrator'
import isCloseManager from '../../utils/isCloseManager'
import { getAuditTrail } from 'src/services/HistoryAPI'

const { confirm } = Modal

const CompanySettings = () => {
  const buttonRef = useRef<string>()

  /** Hooks */
  const isDirty = useUnapprovedInvoicesStore((state) => state.isDirty)
  const setIsEditMode = useUnapprovedInvoicesStore(
    (state) => state.setIsEditMode
  )
  const setHistoryApi = useSettingsStore((state) => state.setHistoryApi)
  const isFlagEnabled = useIsFlagEnabled()
  const navigate = useNavigate()

  const userHasPermission =
    isGappifyAdministrator() || isSystemAdministrator() || isCloseManager()

  // Block navigating elsewhere with the following criteria:
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      // If form has unsaved changes
      isDirty &&
      // If current page is not the same as the next page
      currentLocation.pathname !== nextLocation.pathname
    )
  })

  const showUnsavedModal = (
    onOkCallback: () => void,
    onCancelCallback: () => void
  ) => {
    confirm({
      title: 'Unsaved changes',
      content:
        'You have unsaved changes. If you leave this page, they will be discarded.',
      centered: true,
      okText: 'Leave',
      cancelText: 'Cancel',
      onOk: onOkCallback,
      onCancel: () => {
        // If user clicks 'Discard' button, perform cancel action
        if (buttonRef.current === 'discard') {
          onCancelCallback()
        }
        // Else if the user clicks the 'X' button, only close the modal
      },
      closeIcon: (
        <div onClick={() => (buttonRef.current = 'close')}>
          <CloseOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </div>
      ),
      closable: true
    })
  }

  useEffect(() => {
    // TODO: integration of Audit Trail
    setHistoryApi(() => getAuditTrail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: remove checking for `sync_all` flag once this page can contain more sections
    if (!userHasPermission || !isFlagEnabled(FEATURE_FLAGS.sync_all)) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Use effect for initializing global states
  useEffect(() => {
    /**
     * Set the initial states for the Unapproved Invoices settings
     * ? DEV NOTES: This is only applicable for global states
     */
    setIsEditMode(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Use effect for detecting unsaved changes
  useEffect(() => {
    if (blocker.state === 'blocked') {
      buttonRef.current = 'discard'
      showUnsavedModal(
        () => {
          // Add slight delay to allow closing of modal first before navigating to the next location
          setTimeout(() => {
            // Proceed with navigating to the next location
            blocker.proceed && blocker.proceed()
          }, 200)
        },
        () => {
          // Cancel navigation
          blocker.reset && blocker.reset()
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker])

  return (
    <>
      <CompanySettingsTitle isHistoryEnabled={true} />
      <div className={styles.container}>
        {/* For Sync All settings */}
        {isFlagEnabled(FEATURE_FLAGS.sync_all) && <AccrualManager />}

        {/* For Unapproved Invoices settings */}
        <UnapprovedInvoices />
      </div>
    </>
  )
}

export default CompanySettings
