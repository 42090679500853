// ** Zustand Imports
import { create } from 'zustand'

// ** Types
import UnapprovedInvoicesProps from '../types/UnapprovedInvoicesProps'

const useUnapprovedInvoicesStore = create<UnapprovedInvoicesProps>(
  (set, get) => ({
    // Define initial state
    isDirty: false,
    isError: false,
    isEditMode: false,
    isSavingCompanySettings: false,
    invoiceStatusesValues: [],
    isPOLineInvoicedAmountChecked: false,

    // Define actions
    setIsDirty: (isDirty: boolean) => set({ isDirty }),
    setIsError: (isError: boolean) => set({ isError }),
    setIsEditMode: (isEditMode: boolean) => set({ isEditMode }),
    setIsSavingCompanySettings: (isSavingCompanySettings: boolean) =>
      set({ isSavingCompanySettings }),
    setInvoiceStatusesValues: (invoiceStatusesValues: string[]) =>
      set({ invoiceStatusesValues }),
    setIsPOLineInvoicedAmountChecked: (
      isPOLineInvoicedAmountChecked: boolean
    ) => set({ isPOLineInvoicedAmountChecked })
  })
)

export default useUnapprovedInvoicesStore
