import { RouteObject } from 'react-router-dom'
import Qje from '../pages/Qje'
import QjeReviewedPoClosure from '../pages/QjeReviewedPoClosure'
import QjeReviewJe from '../pages/QjeReviewJe'
import QjeReviewPoClosure from '../pages/QjeReviewPoClosure'
import QjeUnreviewedPoClosure from '../pages/QjeUnreviewedPoClosure'
// import ReviewCenter from '../pages/ReviewCenter'
import Settings from '../pages/Settings'
import AccrualRules from '../pages/Settings/pages/AccrualRules'
// import TaskManager from '../pages/TaskManager'
import TransactionLines from '../pages/TransactionLines'
import TransactionPage from '../pages/TransactionPage'
import TRDTransactionLines from '../pages/TRDTransactionLines'
import TRDTransactionPage from '../pages/TRDTransactionPage'
// import Roles from '../pages/Settings/pages/Roles'
import LoginAuditTrail from '../pages/Settings/pages/LoginAuditTrail'
import AccrualAnalytics from '../pages/AccrualAnalytics'
import ScheduleManager from '../pages/Settings/pages/ScheduleManager'
// import TxnEnableFeatures from '../pages/Settings/pages/TxnEnableFeatures'
import AnalyticsSettings from '../pages/Settings/pages/AnalyticsSettings'
import TransactionConsolidated from '../pages/TransactionConsolidated'
import TransactionConsolidatedLines from '../pages/TransactionConsolidatedLines'
import TxnColumnManager from '../pages/Settings/pages/TxnColumnManager'
import ApiKeys from '../pages/Settings/pages/ApiKeys'
import WorkdaySetup from '../pages/Settings/pages/WorkdaySetup'
import Logs from 'src/pages/Settings/pages/Logs'

// ** New QJE components **
import Qje2 from '../pages/Qje2'
import ReviewJE2 from '../pages/Qje2/views/ReviewJe'
import ReviewPoClosure2 from '../pages/Qje2/views/ReviewPoClosure'
import ReviewedPoClosure2 from '../pages/Qje2/views/ReviewPoClosure/views/ReviewedPoClosure'
import UnreviewedPoClosure2 from '../pages/Qje2/views/ReviewPoClosure/views/UnreviewedPoClosure'

// ** For Q3 Release **
import ReviewCenterSettings from '../pages/Settings/pages/ReviewCenter'
import ReviewCenterWrapper from '../pages/ReviewCenterWrapper'
import ReviewCenter from '../pages/ReviewCenter' // Temporarily added before Q3 release
import UserWrapper from 'src/pages/Settings/pages/Users/UserWrapper'
import PeriodManagement from 'src/pages/Settings/pages/PeriodManagement'
// import TxnEnableFeaturesWrapper from 'src/pages/Settings/pages/TxnEnableFeatures/indexWrapper'

import SSO from 'src/pages/Settings/pages/SSO'
import CompanySettings from 'src/pages/Settings/pages/CompanySettings'
import AccrualManager from 'src/pages/AccrualManager'
import TxnEnableFeatures from 'src/pages/Settings/pages/TxnEnableFeatures'

const privateRoutes: RouteObject[] = [
  {
    path: '/review-center',
    caseSensitive: true,
    element: <ReviewCenter />
  },
  {
    path: '/review-center-new',
    caseSensitive: true,
    element: <ReviewCenterWrapper />
  },
  {
    path: '/task-manager',
    caseSensitive: true,
    element: <AccrualManager />
  },
  {
    path: '/transactions',
    caseSensitive: true,
    element: <TransactionPage />
  },
  {
    path: 'transactions/:id/lines',
    caseSensitive: true,
    element: <TransactionLines />
  },
  {
    path: '/transactions-rd',
    caseSensitive: true,
    element: <TRDTransactionPage />
  },
  {
    path: 'transactions-rd/lines',
    caseSensitive: true,
    element: <TRDTransactionLines />
  },
  {
    path: 'transactions-rd/:txnId/lines',
    caseSensitive: true,
    element: <TRDTransactionLines />
  },
  {
    path: '/transactions-consolidated',
    caseSensitive: true,
    element: <TransactionConsolidated />
  },
  {
    path: '/transactions-consolidated/lines',
    caseSensitive: true,
    element: <TransactionConsolidatedLines />
  },
  {
    path: '/transactions-consolidated/lines/:periodHeader',
    caseSensitive: true,
    element: <TransactionConsolidatedLines />
  },
  {
    path: 'transactions-consolidated/:txnId/lines',
    caseSensitive: true,
    element: <TransactionConsolidatedLines />
  },
  {
    path: 'transactions-consolidated/:txnId/:periodHeader/lines',
    caseSensitive: true,
    element: <TransactionConsolidatedLines />
  },
  {
    path: '/qje2',
    caseSensitive: true,
    element: <Qje2 />,
    children: [
      {
        path: '/qje2/review-je',
        caseSensitive: true,
        element: <ReviewJE2 />
      },
      {
        path: '/qje2/review-po-closure',
        caseSensitive: true,
        element: <ReviewPoClosure2 />,
        children: [
          {
            path: '/qje2/review-po-closure/unreviewed',
            caseSensitive: true,
            element: <UnreviewedPoClosure2 />
          },
          {
            path: '/qje2/review-po-closure/reviewed',
            caseSensitive: true,
            element: <ReviewedPoClosure2 />
          }
        ]
      }
    ]
  },
  {
    path: '/qje',
    caseSensitive: true,
    element: <Qje />,
    children: [
      {
        path: '/qje/review-je',
        caseSensitive: true,
        element: <QjeReviewJe />
      },
      {
        path: '/qje/review-po-closure',
        caseSensitive: true,
        element: <QjeReviewPoClosure />,
        children: [
          {
            path: '/qje/review-po-closure/unreviewed',
            caseSensitive: true,
            element: <QjeUnreviewedPoClosure />
          },
          {
            path: '/qje/review-po-closure/reviewed',
            caseSensitive: true,
            element: <QjeReviewedPoClosure />
          }
        ]
      }
    ]
  },
  {
    path: '/settings',
    caseSensitive: true,
    element: <Settings />,
    children: [
      {
        path: '/settings/user-management/users',
        caseSensitive: true,
        element: <UserWrapper />
      },
      // {
      //   path: '/settings/user-management/roles',
      //   caseSensitive: true,
      //   element: <Roles />
      // },
      {
        path: '/settings/user-management/login-audit-trail',
        caseSensitive: true,
        element: <LoginAuditTrail />
      },
      {
        path: '/settings/user-management/sso',
        caseSensitive: true,
        element: <SSO />
      },
      {
        path: '/settings/schedule-manager',
        caseSensitive: true,
        element: <ScheduleManager />
      },
      {
        path: '/settings/accrual-rules',
        caseSensitive: true,
        element: <AccrualRules />
      },
      {
        path: '/settings/txn-enable-features',
        caseSensitive: true,
        // element: <TxnEnableFeaturesWrapper /> //revert codes, will deploy in different schedule
        element: <TxnEnableFeatures />
      },
      {
        path: '/settings/company-settings',
        caseSensitive: true,
        // element: <TxnEnableFeaturesWrapper />
        element: <CompanySettings />
      },
      {
        path: '/settings/review-center',
        caseSensitive: true,
        element: <ReviewCenterSettings />
      },
      {
        path: '/settings/accrual-analytic',
        caseSensitive: true,
        element: <AnalyticsSettings />
      },
      {
        path: '/settings/transaction-column-manager',
        caseSensitive: true,
        element: <TxnColumnManager />
      },
      {
        path: '/settings/integrations/api-keys',
        caseSensitive: true,
        element: <ApiKeys />
      },
      {
        path: '/settings/integrations/workday-setup',
        caseSensitive: true,
        element: <WorkdaySetup />
      },
      {
        path: '/settings/period-management',
        caseSensitive: true,
        element: <PeriodManagement />
      },
      {
        path: '/settings/integrations/logs',
        caseSensitive: true,
        element: <Logs />
      }
    ]
  },
  {
    path: '/insights',
    caseSensitive: true,
    element: <AccrualAnalytics />
  }
]

export default privateRoutes
