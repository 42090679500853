/** React Imports */
import { useEffect, useRef } from 'react'

/** Antd imports */
import { Select, Spin, Typography } from 'antd'

/** Components */
import TooltipIcon from '../../TooltipIcon'

/** Hooks */
import useUnapprovedInvoicesStore from '../manager/unapproved-invoices-store'

/** Styles */

const { Text } = Typography

type TProps = {
  invoiceStatuses: string[]
  isLoading: boolean
}

const InvoiceStatuses = ({ invoiceStatuses, isLoading }: TProps) => {
  /** Local states */

  /** Local refs */
  const isKeyboardDeleteRef = useRef<boolean>(false) // We use ref here to track keyboard delete action because using state is delayed when capturing new value

  /** Global store */
  const isEditMode = useUnapprovedInvoicesStore((state) => state.isEditMode)
  const isSavingCompanySettings = useUnapprovedInvoicesStore(
    (state) => state.isSavingCompanySettings
  )
  const invoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.invoiceStatusesValues
  )
  const setInvoiceStatusesValues = useUnapprovedInvoicesStore(
    (state) => state.setInvoiceStatusesValues
  )

  /** Helpers */
  const resetStates = () => {
    // Reset keyboard delete flag after handling
    isKeyboardDeleteRef.current = false
  }

  const handleChange = (values: string[]) => {
    // Detect removed value by comparing previous and current selected values
    const removedValue = invoiceStatusesValues.find(
      (value) => !values.includes(value)
    )

    // Check if keyboard delete action is triggered from either Backspace or Enter
    // This action will be ignored
    if (removedValue && isKeyboardDeleteRef.current) {
      resetStates()
      return
    }

    setInvoiceStatusesValues(values)
    resetStates()
  }

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    const key = event.key

    // Set keyboard delete flag if Backspace or Enter is pressed
    if (key === 'Backspace' || key === 'Enter') {
      isKeyboardDeleteRef.current = true
    } else {
      isKeyboardDeleteRef.current = false
    }
  }

  const filterOption = (input: string, option: any) => {
    // Allow filtering based on exact match
    return option.value === input
  }

  /** Use effects */
  useEffect(() => {
    if ((invoiceStatuses && invoiceStatuses.length) || !isLoading) {
      setInvoiceStatusesValues(invoiceStatuses)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatuses, isLoading])

  return (
    <div style={{ display: 'flex', gap: 50, margin: '0 50px' }}>
      <Text style={{ flex: '0 0 225px', textAlign: 'right' }}>
        Invoice Statuses
        <TooltipIcon
          data-testid='invoice-statuses-tooltip'
          data-cy='invoice-statuses-tooltip'
          message="Specify which invoice statuses indicate that an invoice has not yet been posted to your accounting system. For example: Draft, Pending Approval, On Hold. These invoices will be included in the system's accrual proposal for Unposted Invoices."
        />
      </Text>
      {isLoading ? (
        <Spin />
      ) : (
        <Select
          data-testid='invoice-statuses-dropdown'
          data-cy='invoice-statuses-dropdown'
          disabled={!isEditMode || isSavingCompanySettings}
          mode='tags'
          style={{ width: '100%' }}
          placeholder='Invoice Status'
          popupClassName='invoice-statuses-options'
          value={invoiceStatusesValues}
          onChange={handleChange}
          onInputKeyDown={handleInputKeyDown}
          filterOption={filterOption}
        />
      )}
    </div>
  )
}

export default InvoiceStatuses
