// ** Third Party Imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** Hook Imports
import useApiService from '../../../../../hooks/useApiService'
import useFlagStore from 'src/features/Flag/stores/flagStore'

// ** API Services Imports
import useTxnEnableFeaturesService from '../services/TxnEnableFeaturesService'

// ** Type Imports **
import PayloadFormat from '../types/PayloadFormat'

// ** Antd imports
import { message } from 'antd'

// ** Zustand imports
import useTxnEnableFeatureStore from '../manager/useTxnEnableFeatureStore'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

// ==============================================================================
export default function useTxnEnableFeatures() {
  // GET api/transactions/prior-period
  // PUT api/transactions/prior-period-update

  /** API services */
  const { TxnEnableFeaturesAPI } = useTxnEnableFeaturesService()
  const { msUrl } = useApiService()

  /**
   * ! TEMPORARY WORKAROUND
   * ? This is a temporary solution to check if the feature flag is enabled.
   * ? Only applicable for custom hooks that are used across multiple features.
   * ? Since the <Flag /> component is not available in the hooks, we need to use this workaround.
   * TODO: Remove this workaround once there is a similar <Flag /> feature available for custom hooks.
   */
  const flags = useFlagStore((state) => state.flags)
  const isFlagEnabled = () => {
    const matchedFlags = flags.filter((item) => {
      const { name, isActive } = item
      const isMatched = isActive && name === FEATURE_FLAGS.edmonton
      return isMatched
    })
    return Boolean(matchedFlags.length)
  }
  const getFlaggedValue = (on: any, off: any) => {
    return isFlagEnabled() ? on : off
  }

  /** zustand states */
  const {
    setPriorPeriodInternal,
    setPriorPeriodTransaction,
    setPriorPeriodVendor,
    setValidateQjeSources,
    setConsolidatedTxnView,
    setPropAccrualAmountHierarchy,
    setNegativeAccrualAmountToPrepaidEntry,
    setConsiderBothDebitAndCreditValues,
    setAccountTypesToShowInReviewCenter
  } = useTxnEnableFeatureStore()

  /** tanstack query */
  const queryClient = useQueryClient()

  const getTxnEnableFeatures = useQuery<PayloadFormat>({
    queryKey: ['txn-enable-features'],
    queryFn: () =>
      TxnEnableFeaturesAPI({
        data: {},
        params: {},
        options: {
          baseURL: getFlaggedValue(msUrl.settings, msUrl.txn_manager),
          endpoint: getFlaggedValue(
            '/api/settings',
            '/api/transactions/feature-flags'
          ),
          method: 'GET'
        }
      }),
    enabled: flags.length > 0
  })

  // ** POST method **
  const { mutate: putTxnEnableFeatures, isLoading: putIsLoading } = useMutation(
    TxnEnableFeaturesAPI,
    {
      onSuccess: (response: { data: PayloadFormat; message: string }) => {
        const { data } = response

        setPriorPeriodInternal(
          data.prior_period_information_on_internal_accrual_forms!
        )
        setPriorPeriodTransaction(
          data.prior_period_information_on_transaction_lines!
        )
        setPriorPeriodVendor(
          data.prior_period_information_on_vendor_accrual_forms!
        )
        setValidateQjeSources(data.validate_qje_sources!)

        setConsolidatedTxnView(data.consolidated_transaction_lines!)

        setPropAccrualAmountHierarchy(data.proposed_accrual_amount_hierarchy!)

        setNegativeAccrualAmountToPrepaidEntry(
          data.negative_accrual_amount_to_prepaid_entry!
        )

        setConsiderBothDebitAndCreditValues(
          data?.consider_both_debit_and_credit_values!
        )

        setAccountTypesToShowInReviewCenter(
          data?.account_types_to_show_in_review_center!
        )

        message.success('Changes saved.')

        // Re-fetch the settings query to prevent UI display delays
        queryClient.invalidateQueries(['txn-enable-features'])
      },
      onError: (error) => {
        message.error('An error occurred.')
      }
    }
  )

  const handleSave = (txnFeatures: PayloadFormat) => {
    putTxnEnableFeatures({
      data: txnFeatures,
      options: {
        baseURL: getFlaggedValue(msUrl.settings, msUrl.txn_manager),
        endpoint: getFlaggedValue(
          '/api/settings',
          '/api/transactions/feature-flags-update'
        ),
        method: 'PUT',
        customHeaders: {
          'User-Token': `${localStorage?.getItem('authToken')}`
        }
      }
    })
  }

  return {
    getTxnEnableFeatures,
    putIsLoading,
    handleSave
  }
}
