import { create } from 'zustand'

interface Sort {
  [key: `sort[${string}]`]: 'asc' | 'desc'
}

interface StoreProps {
  page: number
  per_page: number
  sort: Sort | undefined
  preview_mode: boolean
  custom_filter: string | number | null
  setPage: (page: number) => void
  setPerPage: (per_page: number) => void
  setSort: (sort: Sort | undefined) => void
  setPreviewMode: (preview_mode: boolean) => void
  setCustomFilter: (custom_filter: string | null) => void
}

const useLogsTableStore = create<StoreProps>((set) => ({
  page: 1,
  per_page: 100,
  sort: {
    'sort[created_at]': 'desc'
  },
  preview_mode: false,
  custom_filter: 'integ-logs-failed-lines',
  setPage: (page) => set({ page }),
  setPerPage: (per_page) => set({ per_page }),
  setSort: (sort) => set({ sort }),
  setPreviewMode: (preview_mode) => set({ preview_mode }),
  setCustomFilter: (custom_filter) => set({ custom_filter })
}))

export default useLogsTableStore
