import {
  FC,
  useState,
  useEffect,
  HTMLAttributes,
  SyntheticEvent,
  useRef
} from 'react'
import GDynamicTableProps from '../../types/GDynamicTableProps'
// import OptionsList from '../../types/OptionsList'
import {
  Table,
  Input,
  Tooltip,
  Checkbox,
  InputNumber,
  Select,
  DatePicker,
  Form,
  Popover
  // message
} from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { getDateTimeMomentFormat, getDateMomentFormat } from '../../utils/Date'
import {
  MenuOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
  EditFilled
} from '@ant-design/icons'

import type { ColumnType } from 'antd/es/table'
import type { ResizeCallbackData } from 'react-resizable'
import { Resizable } from 'react-resizable'
import ReactDragListView from 'react-drag-listview'
import moment from 'moment'

import ColumnFilterPanel from '../ColumnFilterPanel'
import GlobalFilterPanel from '../GlobalFilterPanel'
import TransactionPanel from '../TransactionPanel'
import { callGetApi } from '../../services/GenericAPI'
import _ from 'underscore'
import filterSort from '../../utils/filterSort'
import filterOption from '../../utils/filterOption'
import { useGlobalStore } from '../../global/global-store'

// ** Zustand Imports
import useTxnEnableFeatureStore from '../../pages/Settings/pages/TxnEnableFeatures/manager/useTxnEnableFeatureStore'
import { getTransactionHistory } from '../../services/HistoryAPI'
import useTxnGlobalSettingsStore from '../../global/useTxnGlobalSettingsStore'

const { Option } = Select
const MAX_WIDTH = 250
const MIN_WIDTH = 100

const ResizableTitle = (
  props: HTMLAttributes<any> & {
    onResize: (e: SyntheticEvent<Element>, data: ResizeCallbackData) => void
    width: number
  }
) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

interface ExpandIconProps {
  expanded: boolean
  onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void
  record: any
}

const GDynamicTable: FC<GDynamicTableProps> = ({
  columnHeader,
  dataSource,
  scroll,
  onChange,
  expandedRowRender,
  showHeader,
  hasActionColumns,
  hasResizeableColumns,
  hasColumnFilters,
  hasSelectAll,
  hasTransactionPanel,
  hasGlobalFilter,
  pageSize,
  pageSizeOptions,
  defaultPageSize,
  totalItems,
  isServerSideSort,
  isDraggableSort,
  dragProps,
  onRow,
  loading,
  onEditItem,
  optionsList,
  onFilterChange,
  hasAddNewLines,
  onClickPeriod,
  leftChild,
  rightChild,
  hasExportBtn,
  hasCSVExport,
  hasPDFExport,
  hasExcelExport,
  showSorterTooltip,
  onClickExport,
  columnFilterInitValues,
  hasExportPermission,
  listSize,
  getSelectAllCheckbox,
  getLineCheckboxes,
  selectedRowsCount,
  getActions,
  getActionItems,
  hasClearButton,
  hasSaveFilter,
  onClickReset,
  saveFilterOptions,
  onSaveFilter,
  onClickSaveOptions,
  showSaveModal,
  onCancelFilterModal,
  columnFilterDefault,
  onClickDeleteOptions,
  dateTimeFormat,
  isCreateQjeEnable,
  onClickCreateQje,
  current,
  showTxnFilter,
  onNoQjeClick,
  onClickSubmit,
  editToggleCb,
  errorFields,
  isTableEditable,
  editedRowsCount,
  editedRows,
  onClickCancelTbl,
  periodFilterLabel,
  pagination,
  onClickCell,
  showVoidActionBtn,
  isVoidActionBtnDisabled,
  onClickVoid,
  isExportLoading,
  expandable,
  isExpandable,
  onClickExpand,
  onClickCollapse,
  hasQuickSearchBar,
  quickSearchPlaceHolder,
  onQuickSearchBarKeyDown,
  isDraggable,
  rowKey,
  actionGetPopupContainer,
  footer,
  hasCSVWithIdExport
}: GDynamicTableProps): JSX.Element => {
  const [form] = Form.useForm()
  const [data, setData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(loading)
  const [editKey, setEditKey] = useState('')
  const [editCellKey, setEditCellKey] = useState('')
  const canEditTable = useRef(false)
  const hasDoneEdit = useRef(false)
  const [groupedChoices, setGroupedChoices] = useState<any>({})
  // const [tblColumns, setTblColumns]: any = useState([])
  // const [columnWidth, setColumnWidth] = useState(MIN_WIDTH)
  // const [itemIndex, setItemIndex] = useState()

  const {
    showCalculatedPopup,
    showImportedPopup,
    showTransactionsPopup,
    setHistoryData,
    setModalDelay,
    setActiveKey,
    showInternalPopup,
    showManualPopup,
    showVendorPopup,
    showUnpostedInvoicePopup,
    setRcUseNewHistoryTable,
    showQuickActions
  } = useGlobalStore()

  const { txnGlobalSettings } = useTxnGlobalSettingsStore()

  const isPrepaidEntryFeatureFlagEnabled = useTxnEnableFeatureStore(
    (state) => state.negativeAccrualAmountToPrepaidEntry
  )

  const getUniqueEditKey = (row: any) => {
    const appKey =
      row.app_key || row.line_properties?.actions?.qje?.payload?.app_key
    const uniqueEditKey = `${row.id}_${row.transaction_id}_${row.g_accrual_source}_${appKey}`
    return uniqueEditKey
  }

  const getColumns = () => {
    let columns: object[] = []
    if (isDraggableSort) {
      columns.push({
        title: '',
        key: 'drag',
        fixed: 'left',
        width: 50,
        render: () => (
          <label className='drag-handle'>
            <MenuOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              style={{ cursor: 'grab', color: '#9254de' }}
            />
          </label>
        )
      })
    }

    if (hasActionColumns) {
      columns.push({
        title: 'Actions',
        key: 'actions',
        fixed: 'left',
        width: 110,
        render: (item: any, record: any, index: number) => {
          const obj: any = {
            children:
              record.overrideActions?.component ||
              (getActionItems!(record).length > 0 && (
                <div style={{ display: 'inline-flex' }}>
                  <span className='data-table-action'>
                    {showCalculatedPopup ||
                    showImportedPopup ||
                    showTransactionsPopup ||
                    showInternalPopup ||
                    showManualPopup ||
                    showVendorPopup ||
                    showUnpostedInvoicePopup ||
                    showQuickActions ? (
                      <>
                        {/* Check if earlier than Jan 2024 */}
                        {/* If earlier, use old implementation */}
                        {/* Else, use new implementation */}
                        {/* TODO: replace any */}
                        {moment(record?.g_period).isBefore(
                          moment(
                            (txnGlobalSettings as any)[
                              'transaction.history.cutoff_date'
                            ],
                            'YYYY-MM-DD'
                          )
                        ) ? (
                          // OLD HISTORY
                          <HistoryOutlined
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            onClick={() => {
                              const action =
                                record.line_properties &&
                                record.line_properties.actions

                              callGetApi(
                                action?.history.url!,
                                action?.history.payload!
                              ).then((res) => {
                                setModalDelay(true)
                                setHistoryData(res && res.data)
                                setActiveKey('2')
                                setRcUseNewHistoryTable(false)
                              })
                            }}
                          />
                        ) : (
                          // NEW HISTORY
                          <HistoryOutlined
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            onClick={() => {
                              getTransactionHistory(
                                record?.g_accrual_source,
                                record?.id
                              ).then((res) => {
                                setModalDelay(true)
                                setHistoryData(res && res.data.data)
                                setActiveKey('2')
                                setRcUseNewHistoryTable(true)
                              })
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Popover
                        content={getActions!(record)}
                        placement='bottomLeft'
                        trigger='click'
                        zIndex={99}
                        overlayClassName='action-pop'
                        getPopupContainer={actionGetPopupContainer}
                      >
                        <MoreOutlined
                          onPointerEnterCapture={() => {}}
                          onPointerLeaveCapture={() => {}}
                          data-cy={`gdynamic-table-action-button-${index}`}
                          data-testid={`gdynamic-table-action-button-${index}`}
                        />
                      </Popover>
                    )}
                    {/* <GDropdownBtn
                data-testid='column-action'
                menuItems={getActionItems(record)}
                btnText=''
                btnIcon={<MoreOutlined />}
                // onClick={handleActionOnclick(record)}
              /> */}
                  </span>
                </div>
              )),
            props: item.overrideActions?.props || {
              colSpan: 1
            }
          }
          return obj
        }
      })
    }

    if (hasSelectAll && !canEditTable.current) {
      columns.push({
        title: () => getSelectAllCheckbox!(),
        key: 'selectAll',
        fixed: 'left',
        width: 50,
        render: (item: any, record: any, index: number) => {
          const obj: any = {
            children:
              item.overrideSelectBox?.component ?? getLineCheckboxes!(item),
            props: item.overrideSelectBox?.props ?? { colSpan: 1 }
          }
          return obj
        }
      })
    }

    columnHeader &&
      columnHeader.forEach((item: any, index: number) => {
        if (!item.hidden) {
          const title = item.label ? item.label : item.title
          const dataIndex: any = item.api_name
            ? item.api_name
            : item.dataIndex
            ? item.dataIndex
            : '-'
          const key = item.id ? item.id : item.key
          // const width = item.label === 'Status' ? 80 : MIN_WIDTH
          const column = {
            title: item.has_header_tooltip ? (
              <Tooltip placement='topLeft' title={title}>
                {title}
              </Tooltip>
            ) : (
              title
            ),
            dataIndex: dataIndex,
            key: key,
            children: item.children,
            width: item.width ? item.width : displayTextWidth(title),
            onHeaderCell: (cell: any) => ({
              width: (column as ColumnType<any>).width,
              onResize: handleResize(index, cell)
            }),
            render:
              item.render && !isTableEditable
                ? item.render
                : (input: any, row: any, index: number) => (
                    <div
                      style={{
                        maxWidth: item.width ? item.width : MAX_WIDTH
                      }}
                      className={
                        row.editedField &&
                        row.editedField.includes(item.api_name) &&
                        !(
                          editKey === getUniqueEditKey(row) &&
                          editCellKey === item.api_name
                        ) &&
                        'edited-field'
                      }
                    >
                      {getRenderItem(item, input, row, dataIndex, index)}
                    </div>
                  ),
            fixed: item.fixed,
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: item.sorter
              ? item.sorter
              : item.is_sortable && isServerSideSort
            // : isSortableAndClientSide(item.is_sortable, !isServerSideSort)
            // ? (a: any, b: any) => {
            //     if (
            //       typeof a[dataIndex] == 'number' ||
            //       typeof a[dataIndex] == 'number'
            //     ) {
            //       return a[dataIndex] - b[dataIndex]
            //     } else {
            //       a = a[dataIndex] || ''
            //       b = b[dataIndex] || ''
            //       return a.localeCompare(b)
            //     }
            //   }
            // : true
          }
          columns.push(column)
        }
      })

    return columns
  }

  const displayTextWidth = (text: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '13px Open Sans'
      return context!.measureText(text).width + 60
    }
    return MIN_WIDTH
  }

  // eslint-disable-next-line
  const isSortableAndClientSide = (
    is_sortable: boolean,
    isClientSideSort: boolean
  ) => {
    if (is_sortable && isClientSideSort) {
      return true
    }
    return false
  }

  const getRenderItem = (
    column: any,
    value: any,
    row: any,
    key: string,
    index: number
  ) => {
    if (
      (editKey === getUniqueEditKey(row) &&
        editCellKey === column.api_name &&
        !column.hidden &&
        !column.read_only &&
        row.line_properties?.actions?.override.enabled) ||
      (row.fieldErrors && row.fieldErrors.keys.includes(key))
    ) {
      return (
        <Form form={form} onFinish={handleSubmit}>
          {getEditableField(column, row, key, index)}
        </Form>
      )
    }

    return column.render ? (
      <div
        onClick={() => handleOnClickCell(row, column)}
        style={{ minHeight: '20px' }}
        className='dynamic-tbl-cell'
      >
        {column.render(value, row, index)}
        {canEditTable.current &&
          !column.hidden &&
          !column.read_only &&
          !row.isPriorPeriodLine &&
          row.line_properties?.actions?.override.enabled && (
            <EditFilled
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              className='scale-up-center edit-tbl-icon'
            />
          )}
      </div>
    ) : (
      getDisplayItem(column, value, key, row)
    )
  }

  const getDisplayItem = (
    column: any,
    value: any,
    key: string,
    row: any
  ): JSX.Element => {
    const display = (
      <div
        className={
          column.data_type === 'decimal'
            ? 'data-table-cell text-align-right dynamic-tbl-cell'
            : 'data-table-cell dynamic-tbl-cell'
        }
        style={{
          minHeight: '20px'
        }}
        onClick={() => handleOnClickCell(row, column)}
      >
        {getFormatted(column, value, row)}
        {canEditTable.current &&
          !column.hidden &&
          !column.read_only &&
          !row.isPriorPeriodLine &&
          row.line_properties?.actions.override.enabled && (
            <EditFilled
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              className='scale-up-center edit-tbl-icon'
              style={{ right: '0px' }}
            />
          )}
      </div>
    )

    if (column.has_tooltip || displayTextWidth(value) > MAX_WIDTH) {
      return (
        <Tooltip placement='topLeft' title={value}>
          {display}
        </Tooltip>
      )
    } else {
      return display
    }
  }

  const getFormatted = (column: any, value: any, row: any) => {
    if (value) {
      if (
        (column.data_type === 'dateTime' && column.field_type === 'date') ||
        column.data_type === 'timestamp'
      ) {
        if (dateTimeFormat) {
          return getDateTimeMomentFormat(
            value,
            dateTimeFormat.date_format,
            dateTimeFormat.time_format
          )
        }
        return moment(value).format('MM/DD/YYYY hh:mm a')
      } else if (column.field_type === 'date') {
        if (dateTimeFormat) {
          return getDateMomentFormat(value, dateTimeFormat.date_format)
        }
        return moment(value).format('MM/DD/YYYY')
      } else if (
        // (value && column.field_type === 'numeric_accounting')
        value &&
        column.data_type === 'decimal'
      ) {
        const twoDecimal = parseFloat(value).toFixed(2)
        return twoDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if (column.data_type === 'link') {
        return (
          <a
            href={row.link}
            target='_blank'
            rel='noreferrer'
            style={{ color: '#725bb4' }}
          >
            {value}
          </a>
        )
      }
    }

    return value
  }
  const handleOnClickCell = (row: any, column: any) => {
    if (!row.isPriorPeriodLine) {
      if (canEditTable.current) {
        setEditKey(getUniqueEditKey(row))
        setEditCellKey(column.api_name)
        form.setFieldsValue({ [column.api_name]: row[column.api_name] })
      }

      onClickCell && onClickCell(row, column)
    }
  }

  /**
   * ENG-8300 (https://gappify.atlassian.net/browse/ENG-8300)
   * Apply debounce on keyup event to fix performance issue
   * Utilizes debounce function from underscore library
   * This prevents UI re-render on every keyup event
   */
  const handleKeyUpDebounced = _.debounce(
    (data: any, row: any, key: any, index: any) => {
      if (onEditItem) {
        onEditItem(data, row, key, index, null)
      }
    },
    1000
  )

  const getHoverErrorIcon = (errorMsg: string) => {
    let message = ''
    if (errorMsg) {
      if (errorMsg[0].includes('g_')) {
        let remove_g = errorMsg[0].replace('g_', ' ')
        message = remove_g.replace(new RegExp('_', 'g'), ' ')
      } else {
        message = errorMsg[0]
      }
    }

    return (
      <Tooltip placement='topLeft' title={message}>
        <ExclamationCircleFilled
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          style={{ color: 'red', marginLeft: '5px', fontSize: '12px' }}
        />
      </Tooltip>
    )
  }

  const getEditableField = (
    column: any,
    row: any,
    key: string,
    index: number
  ) => {
    const isPrepaidEntryEnabled =
      isPrepaidEntryFeatureFlagEnabled && column.api_name === 'g_accrual_amount'

    switch (column.field_type) {
      case 'short_text':
        return (
          <Form.Item
            name={
              column.api_name
                ? column.api_name + `-${index}`
                : column.dataIndex + `-${index}`
            }
            // initialValue={row[key]}
            rules={[
              {
                required: column.required,
                message: 'This field is rquired.'
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <Input
              defaultValue={row[key]}
              onFocus={() => {
                setEditKey(getUniqueEditKey(row))
                setEditCellKey(column.api_name)
              }}
              onKeyUp={async (e: any) => {
                let copyNumberInputs: any = {}
                copyNumberInputs[column.api_name] = e.target.value
                row[key] = e.target.value
                hasDoneEdit.current = true
                row.editedField = row.editedField ? row.editedField : []
                if (
                  !row.editedField.includes(column.api_name) ||
                  row.editedField.length === 0
                ) {
                  row.editedField.push(column.api_name)
                }
                if (row.is_edited === undefined || !row.is_edited) {
                  row.is_edited = true
                }

                handleKeyUpDebounced(copyNumberInputs, row, key, index)
              }}
            />
            {row.fieldErrors &&
              !_.isEmpty(row.fieldErrors) &&
              row.fieldErrors.keys.includes(key) &&
              getHoverErrorIcon(row.fieldErrors.msgs[key])}
          </Form.Item>
        )

      case 'numeric_accounting':
        return (
          <Form.Item
            name={
              column.api_name
                ? column.api_name + `-${index}`
                : column.dataIndex + `-${index}`
            }
            rules={[
              {
                required: column.required,
                message: `This field is required.`
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <InputNumber
              defaultValue={row[key]}
              style={{
                width: '100%'
              }}
              onChange={async (value: any) => {
                let previousValue = row[key]

                if (!isPrepaidEntryEnabled && value < 0) {
                  row[key] = previousValue
                  return
                }

                let copyNumberInputs: any = {}
                copyNumberInputs[column.api_name] = value
                row[key] = value
                hasDoneEdit.current = true
                row.editedField = row.editedField ? row.editedField : []
                if (
                  !row.editedField.includes(column.api_name) ||
                  row.editedField.length === 0
                ) {
                  row.editedField.push(column.api_name)
                }
                if (row.is_edited === undefined || !row.is_edited) {
                  row.is_edited = true
                }

                handleKeyUpDebounced(copyNumberInputs, row, key, index)
              }}
              onFocus={() => {
                setEditKey(getUniqueEditKey(row))
                setEditCellKey(column.api_name)
              }}
              step='0.00'
              min={isPrepaidEntryEnabled ? undefined : 0}
            />
            {row.fieldErrors &&
              row.fieldErrors.keys.includes(key) &&
              getHoverErrorIcon(row.fieldErrors.msgs[key])}
          </Form.Item>
        )
      case 'numeric_standard':
        return (
          <Form.Item
            name={
              column.api_name
                ? column.api_name + `-${index}`
                : column.dataIndex + `-${index}`
            }
            initialValue={row[key]}
            rules={[
              {
                required: column.required,
                message: `This field is rquired.`
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <InputNumber
              controls={false}
              type='number'
              onPressEnter={onPressEnterField(key, row, index)}
              onChange={handleOnChangeNumericStandard(key, row, index)}
            />
          </Form.Item>
        )
      case 'picklist_single':
        return (
          <Form.Item
            name={column.api_name ? column.api_name : column.dataIndex}
            rules={[
              {
                required: column.required,
                message: `This field is rquired.`
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <Select
              defaultValue={
                column.data_type === 'boolean'
                  ? row[key] === 1 ||
                    row[key] === 'yes' ||
                    row[key] === 'Yes' ||
                    row[key] === '1'
                    ? 'Yes'
                    : row[key] === 0 ||
                      row[key] === 'no' ||
                      row[key] === 'No' ||
                      row[key] === '0'
                    ? 'No'
                    : row[key]
                  : row[key]
              }
              onChange={onChangeSelector(key, row, index)}
              showSearch
              placeholder=''
              optionFilterProp='children'
              filterOption={filterOption}
              filterSort={filterSort}
              onClick={async () => {
                if (
                  column.options_source?.url &&
                  !Object.keys(groupedChoices).includes(column.api_name!) &&
                  row.api_name !== 'g_name'
                ) {
                  /**
                   * ENG-8839 - Add additional params to exclude inactive options when fetching data source
                   * This is applicable when performing inline edit (TRD/Conso)
                   * Ref: https://gappify.atlassian.net/browse/ENG-8839
                   *
                   * params: exclude_inactive=1
                   */
                  const { data } = await callGetApi(
                    column.options_source.url +
                      '&filter=true&exclude_inactive=1'
                  )
                  const copyGroupedChoices = { ...groupedChoices }
                  copyGroupedChoices[column.api_name!] = data
                  setGroupedChoices(copyGroupedChoices)
                }
              }}
              allowClear
              onClear={() => onChangeSelector(key, row, index)}
            >
              <Option value={''} hidden_value={''}>
                {''}
              </Option>
              {Object.keys(groupedChoices).includes(column.api_name!) ? (
                groupedChoices[column.api_name!].map((option: any) => (
                  <Option
                    key={option.value}
                    hidden_value={
                      option.g_source_system_id
                        ? option.g_source_system_id
                        : option.id
                    }
                    value={option.value}
                  >
                    {option.value}
                  </Option>
                ))
              ) : getOptions(column) && getOptions(column).length > 0 ? (
                getOptions(column).map(
                  (option: any) =>
                    option && (
                      <Option
                        key={
                          option.g_source_system_id
                            ? option.g_source_system_id
                            : option.id
                        }
                        value={
                          option.g_source_system_id
                            ? option.g_source_system_id
                            : option.id
                        }
                        hidden_value={
                          option.g_source_system_id
                            ? option.g_source_system_id
                            : option.id
                        }
                      >
                        {option.value}
                      </Option>
                    )
                )
              ) : Array.isArray(column.options_source) ? (
                column.options_source &&
                column.options_source.map((item: any) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.value}
                    </Option>
                  )
                })
              ) : (
                <Option value={''} disabled>
                  Loading options...
                </Option>
              )}
            </Select>
            {row.fieldErrors &&
              row.fieldErrors.keys.includes(key) &&
              getHoverErrorIcon(row.fieldErrors.msgs[key])}
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item
            name={column.api_name ? column.api_name : column.dataIndex}
            initialValue={row[key] === 'Yes' ? true : false}
            rules={[
              {
                required: column.required,
                message: `This field is rquired.`
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <Checkbox onChange={onChangeCheckbox(key, row, index)} />
          </Form.Item>
        )
      case 'date':
        return (
          <Form.Item
            name={column.api_name ? column.api_name : column.dataIndex}
            initialValue={
              row[key] === null || row[key] === undefined
                ? undefined
                : moment(row[key])
            }
            rules={[
              {
                required: column.required,
                message: `This field is rquired.`
              }
            ]}
            validateStatus={
              row.fieldErrors && row.fieldErrors.keys.includes(key) && 'error'
            }
          >
            <DatePicker
              defaultValue={
                row[key] === null || row[key] === undefined
                  ? undefined
                  : moment(row[key])
              }
              onChange={onChangeDate(key, row, index)}
              allowClear={false}
            />
            {row.fieldErrors &&
              row.fieldErrors.keys.includes(key) &&
              getHoverErrorIcon(row.fieldErrors.msgs[key])}
          </Form.Item>
        )
    }
  }

  const getOptions = (column: any) => {
    // if (column.field_options_source) {
    //   return optionsList![column.field_options_source as keyof OptionsList]
    // }
    // return column.field_options
    return []
  }

  const onPressEnterField =
    (key: string, row: any, index: number) => (e: any) => {
      let value = e.target.value
      let data = { [key]: value }

      const newData = [...dataSource]
      newData[index][key] = value
      setData(newData)

      hasDoneEdit.current = true
      row.editedField = row.editedField ? row.editedField : []
      if (!row.editedField.includes(key) || row.editedField.length === 0) {
        row.editedField.push(key)
      }
      if (row.is_edited === undefined || !row.is_edited) {
        row.is_edited = true
      }

      onEditItem && onEditItem(data, row, key, index, null)
    }

  const handleOnChangeNumericStandard =
    (key: string, row: any, index: number) => (value: any) => {
      let data = { [key]: value }

      const newData = [...dataSource]
      newData[index][key] = value
      setData(newData)

      hasDoneEdit.current = true
      row.editedField = row.editedField ? row.editedField : []
      if (!row.editedField.includes(key) || row.editedField.length === 0) {
        row.editedField.push(key)
      }
      if (row.is_edited === undefined || !row.is_edited) {
        row.is_edited = true
      }

      onEditItem && onEditItem(data, row, key, index, null)
    }

  const onChangeCheckbox =
    (key: string, row: any, index: number) => (e: any) => {
      let value = e.target.checked
      let data = { [key]: value }

      const newData = [...dataSource]
      newData[index][key] = value === true ? 'Yes' : 'No'
      setData(newData)
      hasDoneEdit.current = true
      row.editedField = row.editedField ? row.editedField : []
      if (!row.editedField.includes(key) || row.editedField.length === 0) {
        row.editedField.push(key)
      }
      if (row.is_edited === undefined || !row.is_edited) {
        row.is_edited = true
      }

      onEditItem && onEditItem(data, row, key, index, null)
    }

  const onChangeDate =
    (key: string, row: any, index: number) =>
    (date: any, dateString: string) => {
      hasDoneEdit.current = true
      let data = { [key]: moment(dateString).format('MM/DD/YYYY') }

      const newData = [...dataSource]
      newData[index][key] = dateString
      setData(newData)
      row.editedField = row.editedField ? row.editedField : []
      if (!row.editedField.includes(key) || row.editedField.length === 0) {
        row.editedField.push(key)
      }
      if (row.is_edited === undefined || !row.is_edited) {
        row.is_edited = true
      }

      onEditItem && onEditItem(data, row, key, index, null)
    }

  const onChangeSelector =
    (key: string, row: any, index: number) => (value: any, option: any) => {
      row.editedField = row.editedField ? row.editedField : []

      if (
        !row.editedField.includes(key) ||
        !row.editedField.includes(`${key}_id`) ||
        row.editedField.length === 0
      ) {
        row.editedField.push(key)
      }
      if (row.is_edited === undefined || !row.is_edited) {
        row.is_edited = true
      }

      let data = { [key]: value }
      if (value === undefined) {
        data = { [key]: null }
      }
      const newData = [...dataSource]
      newData[index][key] = value
      if (option !== undefined) {
        newData[index][`${key}_id`] = option.hidden_value
      } else {
        newData[index][`${key}_id`] = null
      }

      setData(newData)

      hasDoneEdit.current = true
      onEditItem && onEditItem(data, row, key, index, option)
    }

  const onChangeEditToggle = (checked: boolean) => {
    // setCanEditTable(checked)
    canEditTable.current = checked
    if (!checked) setEditKey('')

    editToggleCb && editToggleCb(canEditTable.current)
  }

  const onDragEnd = (fromIndex: any, toIndex: any) => {
    const list = [...data]
    const item = list.splice(fromIndex - 1, 1)[0]
    list.splice(toIndex - 1, 0, item)
    setData(list)
  }

  const handleResize =
    (index: any, cell: any) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...getColumns()]
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      }

      // setTblColumns(newColumns)
    }

  const handleSubmit = (values: any) => {
    form.resetFields()
  }

  const onClickSubmitBtn = () => {
    form.submit()
    setEditKey('')
    hasDoneEdit.current = false
    onClickSubmit && onClickSubmit(form)
  }

  const getRowClassName = (record: any, index: number) => {
    let rowClassName = ''
    if (record.is_edited) {
      rowClassName = `${rowClassName} tbl-edited-row`
    }
    if (!record.parent) {
      rowClassName = `${rowClassName} child-row`
    }
    return rowClassName.trim()
  }

  useEffect(() => {
    if (errorFields && errorFields.length !== 0) {
      if (dataSource.length === 0) {
        setData(dataSource)
      } else {
        let newDataSource = data.map((item: any, index: number) => {
          let keys = Object.keys(errorFields)
          if (keys.includes(index.toString())) {
            return {
              ...item,
              fieldErrors: {
                keys: Object.keys(errorFields[index]),
                msgs: errorFields[index]
              }
            }
          }

          return item
        })
        setData(newDataSource)
      }
    } else {
      setData(dataSource)
    }
    setIsLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, loading, errorFields])

  const expandIcon = ({ expanded, onExpand, record }: ExpandIconProps) => {
    if (
      isExpandable &&
      record.parent &&
      [0, 1].includes(record.g_line_source) &&
      record.g_accrual_type !== 'Imported' &&
      record.g_accrual_type !== 'Unposted Invoice'
    ) {
      // if (isExpandable && record.parent && record.g_accrual_type === 'Percent') {
      if (expanded) {
        return (
          <div style={{ display: 'inline-flex', float: 'right' }}>
            <small>
              <UpOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                style={{
                  position: 'relative',
                  top: 2,
                  marginRight: 30
                }}
                onClick={async (
                  e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                ) => {
                  // ** Commenting this out, for future refercne **
                  // await onClickCollapse(record)
                  return onExpand(record, e)
                }}
              />
            </small>
          </div>
        )
      } else
        return (
          <div style={{ display: 'inline-flex', float: 'right' }}>
            <small>
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                style={{
                  position: 'relative',
                  top: 2,
                  marginRight: 30
                }}
                onClick={async (
                  e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                ) => {
                  if (record.children.length === 0) await onClickExpand(record)
                  return onExpand(record, e)
                }}
              />
            </small>
          </div>
        )
    } else return null
  }

  const tableProps = {
    expandable: {
      expandIcon: expandIcon
      // ** For testing **
      // onExpand: () => {},
      // expandedRowRender: (record: any) => {
      //   return <div className='child-row'></div>
      // }
    }
  }

  return (
    <>
      {data.forEach((item) => (
        <div>{item}</div>
      ))}
      {hasGlobalFilter && (
        <GlobalFilterPanel
          hasAddNewLines={hasAddNewLines}
          onClickPeriod={onClickPeriod}
          hasExportBtn={hasExportBtn}
          leftChild={leftChild}
          rightChild={rightChild}
          hasCSVExport={hasCSVExport}
          hasPDFExport={hasPDFExport}
          hasExcelExport={hasExcelExport}
          onClickExport={onClickExport}
          hasExportPermission={hasExportPermission}
          showTxnFilter={showTxnFilter}
          periodFilterLabel={periodFilterLabel}
          loading={isExportLoading}
          hasQuickSearchBar={hasQuickSearchBar}
          quickSearchPlaceHolder={quickSearchPlaceHolder}
          onKeyDown={onQuickSearchBarKeyDown}
          hasCSVWithIdExport={hasCSVWithIdExport}
        />
      )}
      {hasTransactionPanel && (
        <TransactionPanel
          countSelected={selectedRowsCount}
          canEditTable={canEditTable.current}
          onEditSwitch={onChangeEditToggle}
          isCreateQjeEnable={isCreateQjeEnable}
          onClickCreateQje={onClickCreateQje}
          showSubmitCancel={hasDoneEdit.current}
          onClickCancel={() => {
            form.resetFields()
            setEditKey('')
            hasDoneEdit.current = false
            onClickCancelTbl && onClickCancelTbl()
          }}
          onClickSubmit={onClickSubmitBtn}
          onNoQjeClick={onNoQjeClick}
          selectedRowsCount={selectedRowsCount}
          editedRowsCount={editedRowsCount}
          editedRows={editedRows}
          showVoidActionBtn={showVoidActionBtn}
          isVoidActionBtnDisabled={isVoidActionBtnDisabled}
          onClickVoid={onClickVoid}
        />
      )}
      {hasColumnFilters && columnHeader && (
        <ColumnFilterPanel
          columns={columnHeader}
          optionsList={optionsList}
          onFilterChange={onFilterChange}
          initialValues={columnFilterInitValues}
          hasClearButton={hasClearButton}
          hasSaveFilter={hasSaveFilter}
          onClickReset={onClickReset}
          saveFilterOptions={saveFilterOptions}
          onSaveFilter={onSaveFilter}
          onClickSaveOptions={onClickSaveOptions}
          showSaveModal={showSaveModal}
          onCancel={onCancelFilterModal}
          columnFilterDefault={columnFilterDefault}
          onClickDeleteOptions={onClickDeleteOptions}
        />
      )}
      <div className='data-table'>
        {isDraggable ? (
          <ReactDragListView
            {...dragProps}
            onDragEnd={onDragEnd}
            handleSelector='label'
          >
            <Table
              {...tableProps}
              rowKey={rowKey}
              className='table'
              data-testid='GDynamicTable'
              data-cy='GDynamicTable'
              dataSource={data}
              columns={getColumns()}
              rowClassName={getRowClassName}
              components={{
                header: {
                  cell: ResizableTitle
                }
              }}
              pagination={
                !pagination
                  ? false
                  : {
                      showTotal: (total: number, range: any) =>
                        `Showing ${range[0]}-${range[1]} of ${total}`,
                      pageSize,
                      total: totalItems,
                      showSizeChanger: true,
                      pageSizeOptions,
                      defaultPageSize,
                      current: current,
                      defaultCurrent: 1
                    }
              }
              onRow={
                onRow
                  ? onRow
                  : (record: any) => {
                      return {
                        onClick: () => {
                          if (canEditTable.current) {
                            if (
                              editKey !== getUniqueEditKey(record) &&
                              editKey !== ''
                            ) {
                              setEditKey('')
                            }
                          }
                        }

                        // onClick: () => {
                        //   if (editKey !== record.id) {
                        //     setEditKey('')
                        //   }
                        // }
                      }
                    }
              }
              scroll={scroll}
              loading={isLoading}
              onChange={onChange}
              expandedRowRender={expandedRowRender}
              showHeader={showHeader}
              showSorterTooltip={showSorterTooltip}
              size={listSize}
              footer={footer}
            />
          </ReactDragListView>
        ) : (
          <Table
            {...tableProps}
            rowKey={rowKey}
            className='table'
            data-testid='GDynamicTable'
            data-cy='GDynamicTable'
            dataSource={data}
            columns={getColumns()}
            rowClassName={getRowClassName}
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            pagination={
              !pagination
                ? false
                : {
                    showTotal: (total: number, range: any) =>
                      `Showing ${range[0]}-${range[1]} of ${total}`,
                    pageSize,
                    total: totalItems,
                    showSizeChanger: true,
                    pageSizeOptions,
                    defaultPageSize,
                    current: current,
                    defaultCurrent: 1
                  }
            }
            onRow={
              onRow
                ? onRow
                : (record: any) => {
                    return {
                      onClick: () => {
                        if (canEditTable.current) {
                          if (
                            editKey !== getUniqueEditKey(record) &&
                            editKey !== ''
                          ) {
                            setEditKey('')
                          }
                        }
                      }

                      // onClick: () => {
                      //   if (editKey !== record.id) {
                      //     setEditKey('')
                      //   }
                      // }
                    }
                  }
            }
            scroll={scroll}
            loading={isLoading}
            onChange={onChange}
            expandedRowRender={expandedRowRender}
            showHeader={showHeader}
            showSorterTooltip={showSorterTooltip}
            size={listSize}
            footer={footer}
          />
        )}
      </div>
    </>
  )
}

GDynamicTable.defaultProps = {
  hasActionColumns: false,
  hasColumnFilters: false,
  hasGlobalFilter: false,
  hasResizeableColumns: false,
  hasSelectAll: false,
  hasTransactionPanel: false,
  isDraggableSort: false,
  isServerSideSort: false,
  loading: false,
  showHeader: false,
  hasAddNewLines: false,
  scroll: { x: 'max-content', y: 500 },
  pageSizeOptions: ['20', '50', '100', '200'],
  hasExportBtn: true,
  showSorterTooltip: false,
  hasClearButton: true,
  hasSaveFilter: false,
  isTableEditable: false,
  pagination: true
}

export default GDynamicTable
