import { TableColumnGroupType, TableColumnType } from 'antd'
import MENU_CONFIG from './MENU_CONFIG'

type SettingsConfig = {
  path: string
  label: string
  description?: string
  isHistoryEnabled?: boolean
  historyColumns?: (TableColumnType<any> | TableColumnGroupType<any>)[]
}

export const historyColumns: (
  | TableColumnType<any>
  | TableColumnGroupType<any>
)[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 200
  },
  {
    title: 'Performed By',
    dataIndex: 'performed_by',
    key: 'performedBy',
    width: 200
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 200
  },
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    width: 200
  },
  {
    title: 'Old Value',
    dataIndex: 'old',
    key: 'oldValue',
    width: 200
  },
  {
    title: 'New Value',
    dataIndex: 'new',
    key: 'newValue',
    width: 200
  }
]

export const auditTrailColumns: (
  | TableColumnType<any>
  | TableColumnGroupType<any>
)[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 200
  },
  {
    title: 'Performed By',
    dataIndex: 'performed_by',
    key: 'performedBy',
    width: 200
  },
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    width: 200
  },
  {
    title: 'Old Value',
    dataIndex: 'old',
    key: 'oldValue',
    width: 200
  },
  {
    title: 'New Value',
    dataIndex: 'new',
    key: 'newValue',
    width: 200
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    width: 200
  }
]

const settingsConfig: SettingsConfig[] = [
  {
    path: '/settings/txn-enable-features',
    label: MENU_CONFIG.TXN_ENABLE_FEATURES.LABEL_NEW,
    isHistoryEnabled: false
  },
  {
    path: '/settings/company-settings',
    label: MENU_CONFIG.COMPANY_SETTINGS.LABEL,
    description:
      'Tailor your company preference and control account-wide settings',
    isHistoryEnabled: true,
    historyColumns: auditTrailColumns
  },
  {
    path: '/settings/user-management/users',
    label: MENU_CONFIG.USER_MANAGEMENT.USERS.LABEL
  },
  {
    path: '/settings/user-management/sso',
    label: MENU_CONFIG.USER_MANAGEMENT.SSO.LABEL,
    description:
      'The setup below will help establish SSO leveraging SAML with the IDP provider of your choice.',
    isHistoryEnabled: true,
    historyColumns: historyColumns
  },
  {
    path: '/settings/user-management/login-audit-trail',
    label: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.LABEL
  },
  {
    path: '/settings/schedule-manager',
    label: MENU_CONFIG.SCHEDULE_MANAGER.LABEL
  },
  {
    path: '/settings/period-management',
    label: MENU_CONFIG.PERIOD_MANAGEMENT.LABEL
  },
  {
    path: '/settings/review-center',
    label: MENU_CONFIG.REVIEW_CENTER.LABEL,
    isHistoryEnabled: true
  },
  {
    path: '/settings/transaction-column-manager',
    label: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.LABEL
  },
  {
    path: '/settings/accrual-rules',
    label: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.LABEL
  },
  {
    path: '/settings/accrual-analytic',
    label: MENU_CONFIG.ANALYTICS_SETTINGS.LABEL
  },
  {
    path: '/settings/integrations/api-keys',
    label: MENU_CONFIG.INTEGRATION.API_KEYS.LABEL
  },
  {
    path: '/settings/integrations/workday-setup',
    label: MENU_CONFIG.INTEGRATION.WORKDAY.LABEL
  },
  {
    path: '/settings/integrations/logs',
    label: MENU_CONFIG.INTEGRATION.LOGS.LABEL
  }
]

export default settingsConfig
