// ** Zustand
import { create } from 'zustand'

interface GlobalProps {
  // Defined state properties
  display: React.ReactNode
  isDisplayedInRC: boolean
  showCalculatedPopup: boolean
  showTransactionsPopup: boolean
  showImportedPopup: boolean
  historyData: any
  showDelay: boolean
  showHistory: boolean
  activeKey: string
  showInternalPopup: boolean
  showManualPopup: boolean
  showVendorPopup: boolean
  showUnpostedInvoicePopup: boolean

  subsidiaryIdParams: string | undefined
  departmentIdParams: number | undefined
  glaccountIdParams: number | undefined
  vendorIdParams: string | undefined
  vendorNameParams: string | undefined
  periodParams: string | undefined
  typeParams: string | undefined
  keyParams: string | undefined
  viewParams: string | undefined
  filterParams: number | undefined

  callReviewCenterReload: boolean
  errorFields: []
  isEditRowCalled: boolean
  isCreateQjeCalled: boolean

  isPasswordExpired: boolean
  passwordResetToken: string | undefined

  txnChildren: Record<string, any>

  // ** Common Source Coa Dropdown List States
  isSourceCoaInactive: boolean
  selectedCoaType: string | undefined

  // ** Active Source Coa Dropdown List
  activeSubsidiaryOptions: []
  activeDepartmentOptions: []
  activeGlAccountOptions: []

  // ** Inactive Source Coa Dropdown List
  inactiveSubsidiaryOptions: []
  inactiveDepartmentOptions: []
  inactiveGlAccountOptions: []

  showQuickActions: boolean
  quickActionsType: string | undefined

  showNewHistoryModal: boolean
  rcUseNewHistoryTable: boolean

  // Defined actions
  setTxnChildren: (txnChildren: Record<any, any>) => void
  setDisplay: (display: React.ReactNode) => void
  setIsDisplayedInRC: (isDisplayedInRC: boolean) => void
  setShowCalculatedPopup: (showCalculatedPopup: boolean) => void
  setShowTransactionsPopup: (showTransactionsPopup: boolean) => void
  setShowImportedPopup: (showImportedPopup: boolean) => void
  setHistoryData: (historyData: any) => void
  setModalDelay: (showDelay: boolean) => void
  setHistoryModalVisible: (showHistory: boolean) => void
  setActiveKey: (activeKey: string) => void
  setShowInternalPopup: (showInternalPopup: boolean) => void
  setShowManualPopup: (showManualPopup: boolean) => void
  setShowVendorPopup: (showVendorPopup: boolean) => void
  setShowUnpostedInvoicePopup: (showUnpostedInvoicePopup: boolean) => void

  setSubdiaryIdParams: (subsidiaryIdParams: string | undefined) => void
  setDepartmentIdParams: (departmentIdParams: number | undefined) => void
  setGlAccountIdParams: (glaccountIdParams: number | undefined) => void
  setVendorIdParams: (vendorIdParams: string | undefined) => void
  setVendorNameParams: (vendorNameParams: string | undefined) => void
  setPeriodParams: (periodParams: string | undefined) => void
  setTypeParams: (typeParams: string | undefined) => void
  setKeyParams: (keyParams: string | undefined) => void
  setViewParams: (viewParams: string | undefined) => void
  setFilterParams: (filterParams: number | undefined) => void

  setCallReviewCenterReload: (value: boolean) => void
  setErrorFields: (errorFields: []) => void
  setIsEditRowCalled: (value: boolean) => void
  setIsCreateQjeCalled: (value: boolean) => void

  setIsPasswordExpired: (isPasswordExpired: boolean) => void
  setPasswordResetToken: (passwordResetToken: string | undefined) => void

  // ** Common Source Coa Dropdown List States
  setIsSourceCoaInactive: (isSourceCoaInactive: boolean) => void
  setSelectedCoaType: (selectedCoaType: string | undefined) => void

  // ** Active Source Coa Dropdown List
  setActiveSubsidiaryOptions: (activeSubsidiaryOptions: []) => void
  setActiveDepartmentOptions: (activeDepartmentOptions: []) => void
  setActiveGlAccountOptions: (activeGlAccountOptions: []) => void

  // ** Inactive Source Coa Dropdown List
  setInActiveSubsidiaryOptions: (inactiveSubsidiaryOptions: []) => void
  setInActiveDepartmentOptions: (inactiveDepartmentOptions: []) => void
  setInActiveGlAccountOptions: (inactiveGlAccountOptions: []) => void

  setShowQuickActions: (showQuickActions: boolean) => void
  setQuickActionsType: (quickActionsType: string | undefined) => void

  setNewHistoryModalVisible: (showNewHistoryModal: boolean) => void
  setRcUseNewHistoryTable: (useRcNewHistoryTable: boolean) => void

  // Functions
}

export const useGlobalStore = create<GlobalProps>((set) => ({
  // Define initial state
  display: null,
  isDisplayedInRC: false,
  showCalculatedPopup: false,
  showTransactionsPopup: false,
  showImportedPopup: false,
  historyData: {},
  showDelay: false,
  showHistory: false,
  activeKey: '1',
  showInternalPopup: false,
  showManualPopup: false,
  showVendorPopup: false,
  showUnpostedInvoicePopup: false,

  subsidiaryIdParams: undefined,
  departmentIdParams: undefined,
  glaccountIdParams: undefined,
  vendorIdParams: undefined,
  vendorNameParams: undefined,
  periodParams: undefined,
  typeParams: undefined,
  keyParams: undefined,
  viewParams: undefined,
  filterParams: undefined,

  callReviewCenterReload: false,
  errorFields: [],
  isEditRowCalled: false,
  isCreateQjeCalled: false,
  isPasswordExpired: false,
  passwordResetToken: undefined,
  txnChildren: {},

  // ** Common Source Coa Dropdown List States
  isSourceCoaInactive: false, // g_inactive = 0 or false
  selectedCoaType: undefined,

  // ** Active Source Coa Dropdown List States
  activeSubsidiaryOptions: [],
  activeDepartmentOptions: [],
  activeGlAccountOptions: [],

  // ** Inactive Source Coa Dropdown List States
  inactiveSubsidiaryOptions: [],
  inactiveDepartmentOptions: [],
  inactiveGlAccountOptions: [],

  showQuickActions: false,
  quickActionsType: undefined,

  showNewHistoryModal: false,
  rcUseNewHistoryTable: false,

  // Define actions
  setTxnChildren: (data) => set({ txnChildren: data }),
  setDisplay: (display) => set({ display }),
  setIsDisplayedInRC: (isDisplayedInRC) => set({ isDisplayedInRC }),
  setShowCalculatedPopup: (showCalculatedPopup) => set({ showCalculatedPopup }),
  setShowTransactionsPopup: (showTransactionsPopup) => ({
    showTransactionsPopup
  }),
  setShowImportedPopup: (showImportedPopup) => set({ showImportedPopup }),
  setHistoryData: (historyData) => set({ historyData }),
  setModalDelay: (showDelay) => set({ showDelay }),
  setHistoryModalVisible: (showHistory) => set({ showHistory }),
  setActiveKey: (activeKey) => set({ activeKey }),
  setShowInternalPopup: (showInternalPopup) => set({ showInternalPopup }),
  setShowManualPopup: (showManualPopup) => set({ showManualPopup }),
  setShowVendorPopup: (showVendorPopup) => set({ showVendorPopup }),
  setShowUnpostedInvoicePopup: (showUnpostedInvoicePopup) =>
    set({ showUnpostedInvoicePopup }),

  setSubdiaryIdParams: (subsidiaryIdParams) => set({ subsidiaryIdParams }),
  setDepartmentIdParams: (departmentIdParams) => set({ departmentIdParams }),
  setGlAccountIdParams: (glaccountIdParams) => set({ glaccountIdParams }),
  setVendorIdParams: (vendorIdParams) => set({ vendorIdParams }),
  setVendorNameParams: (vendorNameParams) => set({ vendorNameParams }),
  setPeriodParams: (periodParams) => set({ periodParams }),
  setTypeParams: (typeParams) => set({ typeParams }),
  setKeyParams: (keyParams) => set({ keyParams }),
  setViewParams: (viewParams) => set({ viewParams }),
  setFilterParams: (filterParams) => set({ filterParams }),

  setCallReviewCenterReload: (value) =>
    set(() => ({ callReviewCenterReload: value })),
  setErrorFields: (errorFields) => set({ errorFields }),
  setIsEditRowCalled: (value) => set({ isEditRowCalled: value }),
  setIsCreateQjeCalled: (value) => set({ isCreateQjeCalled: value }),
  setIsPasswordExpired: (isPasswordExpired) => set({ isPasswordExpired }),
  setPasswordResetToken: (passwordResetToken) => set({ passwordResetToken }),

  // ** Common Source Coa Dropdown List States
  setIsSourceCoaInactive: (isSourceCoaInactive) => set({ isSourceCoaInactive }),
  setSelectedCoaType: (selectedCoaType) => set({ selectedCoaType }),

  // ** Active Source Coa Dropdown List Actions
  setActiveSubsidiaryOptions: (activeSubsidiaryOptions) =>
    set({ activeSubsidiaryOptions }),
  setActiveDepartmentOptions: (activeDepartmentOptions) =>
    set({ activeDepartmentOptions }),
  setActiveGlAccountOptions: (activeGlAccountOptions) =>
    set({ activeGlAccountOptions }),

  // ** Inactive Source Coa Dropdown List Actions
  setInActiveSubsidiaryOptions: (inactiveSubsidiaryOptions) =>
    set({ inactiveSubsidiaryOptions }),
  setInActiveDepartmentOptions: (inactiveDepartmentOptions) =>
    set({ inactiveDepartmentOptions }),
  setInActiveGlAccountOptions: (inactiveGlAccountOptions) =>
    set({ inactiveGlAccountOptions }),

  setShowQuickActions: (showQuickActions) => set({ showQuickActions }),
  setQuickActionsType: (quickActionsType) => set({ quickActionsType }),

  setNewHistoryModalVisible: (showNewHistoryModal) =>
    set({ showNewHistoryModal }),

  setRcUseNewHistoryTable: (rcUseNewHistoryTable) =>
    set({ rcUseNewHistoryTable })

  // Functions
}))
