import { useEffect } from 'react'
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'

// ** Custom Component Imports
import FilterDropdown from './components/FilterDropdown'
import FilterModal from './components/FilterModal'
import GButton from '../gappify/GButton'
import GIcon from '../gappify/GIcon'

// ** Zustand Imports
import useFilterStore from './store/filterStore'

// ** Types/Constants Imports
import TProps, { TFilter } from './types/Filter'
import { DEFAULT_FILTER, DEFAULT_FILTER_IDS, MODAL_MODE } from './constants'

const Filter = (props: TProps) => {
  const { setColumnHeaders, allColumnHeaders } = props

  // ** Zustand Store **
  const selectedFilter = useFilterStore((state) => state.selectedFilter)
  const isPreviewMode = useFilterStore((state) => state.isPreviewMode)
  const allFilters = useFilterStore((state) => state.allFilters)
  const setSelectedFilter = useFilterStore((state) => state.setSelectedFilter)
  const setModalMode = useFilterStore((state) => state.setModalMode)
  const setIsModalOpen = useFilterStore((state) => state.setIsModalOpen)

  const updateColumnHeaders = (key: string, newCustomColumns?: any) => {
    const thisFilter = allFilters.find((item: TFilter) => {
      return item.id === Number(key)
    })
    const thisFilterableFields = thisFilter?.filterable_fields || null

    if (
      /**
       * For Create, Update & Preview actions
       */
      (thisFilterableFields && thisFilterableFields.length) ||
      (newCustomColumns && newCustomColumns.length)
    ) {
      const customColumns = newCustomColumns || thisFilterableFields
      const newColumns = customColumns.map((elem: any) => {
        const selectedElem = allColumnHeaders!.find((ele: any) => {
          return ele.api_name === elem.api_name
        })
        return {
          ...selectedElem,
          weight: elem.weight
        }
      })
      setColumnHeaders(newColumns)
    } else {
      /**
       * Set the default state of the column headers
       * This is from the transaction column manager global settings
       */
      setColumnHeaders(allColumnHeaders)
    }
  }

  const handleOnUpdate = () => {
    setIsModalOpen(true)
    setModalMode(MODAL_MODE.UPDATE)
  }

  const handleOnPreview = () => {
    setIsModalOpen(true)
  }

  const UpdateIcon = () => {
    if (selectedFilter && selectedFilter.id && !isPreviewMode) {
      if (!DEFAULT_FILTER_IDS.includes(selectedFilter.id)) {
        return (
          <GButton
            type='text'
            className='g-btn-icon'
            icon={<GIcon icon={faPencil} />}
            onClick={handleOnUpdate}
          />
        )
      }
    }
    return <></>
  }

  const PreviewIcon = () => {
    if (isPreviewMode) {
      return (
        <GButton
          type='text'
          className='g-btn-icon'
          icon={<GIcon icon={faEye} />}
          onClick={handleOnPreview}
        />
      )
    }
    return <></>
  }

  useEffect(() => {
    // Set the selected filter if it exists
    if (props.selectedFilter) {
      setSelectedFilter(props.selectedFilter)
    } else {
      setSelectedFilter(DEFAULT_FILTER)
    }

    // Cleanup function when component is unmounted
    return () => {
      setSelectedFilter(DEFAULT_FILTER)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <FilterDropdown {...props} updateColumnHeaders={updateColumnHeaders} />
      <UpdateIcon />
      <PreviewIcon />
      <FilterModal {...props} updateColumnHeaders={updateColumnHeaders} />
    </>
  )
}

export default Filter
