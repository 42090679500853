import MsUrl from '../../../types/MsUrl'

const Php8: MsUrl = {
  idms: 'https://idms.qa.gappifyinc.com',
  txn_manager: 'https://ms-transaction-manager.qa.gappify.com',
  review_center: 'https://ms-review-center.qa.gappify.com',
  qje_manager: 'https://ms-qje.qa.gappify.com',
  taskmanager: 'https://ms-accrual-manager.qa.gappify.com',
  history_logs: 'https://ms-history.qa.gappify.com',
  analytics: 'https://ms-advanced-analytics.qa.gappify.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.qa.gappifyinc.com',
  schedule_manager_env: 'qa',
  search: 'https://search.qa.gappifyinc.com',
  period_manager: 'https://period-manager.qa.gappifyinc.com',
  settings: 'https://ms-settings.qa.gappify.com',
  gappify_api: 'https://api.qa.gappify.com'
} as const

export default Php8
