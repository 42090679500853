import { useQuery } from '@tanstack/react-query'
import useApiService from 'src/hooks/useApiService'
import useGlobalService from 'src/services/globalService'

type Response = {
  invoice_statuses: string[]
  po_line_invoice_amount_includes_unapproved_invoices: boolean
}

const useFetchUnapprovedInvoices = () => {
  const { globalServiceApi } = useGlobalService()
  const { msUrl } = useApiService()

  return useQuery<Response>({
    queryKey: ['get-unapproved-invoices'],
    queryFn: () =>
      globalServiceApi({
        options: {
          baseURL: msUrl.settings,
          endpoint: '/api/settings/unposted-invoices',
          method: 'GET'
        }
      })
  })
}

export default useFetchUnapprovedInvoices
