// ** React Imports
import { Path, useResolvedPath } from 'react-router-dom'

// ** AntD Imports
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'

// ** Constant Imports
import MENU_CONFIG from '../../constants/MENU_CONFIG'

// ** Custom Component Imports
import SettingsTitle from '../SettingsTitle'

// ** Hooks Imports
import useCollapsible from '../../hooks/useCollapsible'

// ** Style Imports
import styles from '../../styles/collapsible.module.css'

// ** Zustand Imports
import useGetMenuItems from '../../hooks/useGetMenuItems'
// import useGetMenuItems_q3 from '../../hooks/useGetMenuItems_q3'
import useSettingsStore from '../../manager/settings-store'
// import Flag from '../../../../features/Flag'
// import { FEATURE_FLAGS } from '../../../../features/Flag/constants'

// =================================================================
const Collapsible = () => {
  // ** Router **
  const resolvedPath: Path = useResolvedPath(window.location.pathname)

  // ** MUI **
  const { Sider } = Layout

  // ** Zustand **
  const { collapsed, hovered } = useSettingsStore()

  // ** Hooks **
  const { handleToggle, handleMouseEnter, handleMouseLeave } = useCollapsible()
  const { menuItems } = useGetMenuItems(resolvedPath.pathname)
  // const { menuItems: menuItems_q3 } = useGetMenuItems_q3(resolvedPath.pathname)

  const defaultOpen = [...MENU_CONFIG.DEFAULT_OPEN]

  return (
    <Sider
      className={`${styles.sider} settings-slider`}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={20}
      width={400}
      data-testid='main-collapsible'
    >
      <div className={styles.wrapper} data-testid='collapsible-btn'>
        <RightCircleOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          onClick={handleToggle}
          className={`${hovered ? styles.hovered : styles.btn}`}
          style={{
            right: collapsed ? '-10px' : '300px',
            display: collapsed ? 'block' : 'none'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <LeftCircleOutlined
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          onClick={handleToggle}
          className={`${hovered ? styles.hovered : styles.btn}`}
          style={{
            right: collapsed ? '300px' : '-10px',
            display: collapsed ? 'none' : 'block'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      <SettingsTitle />
      {collapsed ? (
        <Menu mode='horizontal' />
      ) : (
        // <Flag
        //   authorizedFlags={[FEATURE_FLAGS.edinburgh]}
        //   renderOn={
        //     <Menu
        //       items={menuItems_q3}
        //       mode='inline'
        //       defaultOpenKeys={defaultOpen}
        //     />
        //   }
        //   renderOff={
        //     <Menu
        //       items={menuItems}
        //       mode='inline'
        //       defaultOpenKeys={defaultOpen}
        //     />
        //   }
        // />
        //will deploy this in separate schedule
        <Menu items={menuItems} mode='inline' defaultOpenKeys={defaultOpen} />
      )}
    </Sider>
  )
}

export default Collapsible
