// ** React Imports **
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// ** Ant Design Components **
import { Card, Table, TableProps } from 'antd'
import { VList } from 'virtuallist-antd'
import { CheckOutlined } from '@ant-design/icons'

// ** Hooks **
import useTxnGlobalSettings from '../../../hooks/useTxnGlobalSettings'
import useQje from '../hooks/useQje'
import useQjeStore from '../manager/qje-store'

// ** Custom Components **
import ActionButtonsRow from '../components/ActionButtonsRow'
import QjeCustomPager from '../components/QjeCustomPager'
import HeaderLineGroupCheckbox from '../components/HeaderLineGroupCheckbox'
import LineGroupCheckbox from '../components/LineGroupCheckbox'
import GlobalHistoryModal from '../../../components/GlobalHistoryModal'
import VirtualSpinner from '../components/VirtualSpinner'

// ** Utility Functions **
import applyCustomRendering from '../utils/column/applyCustomRendering'
import destructureSettings from '../utils/column/destructureSettings'
import { getQjeLogs } from '../../../services/QjeManagerApi'
import {
  getDateFormat,
  getDateTimeMomentFormat,
  getMomentTimeFormat
} from '../../../utils/Date'
import applyCustomRenderingNonAsync from '../utils/column/applyCustomRenderingNonAsync'

// ** Types **
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import TransactionLine from '../../../types/TransactionLine'

// ** Third party libraries **
import moment from 'moment'

// ====================================================================
const ReviewJE = () => {
  // ** States and Refs **
  const [height, setHeight] = useState(window.innerHeight)
  const [reviewJeData, setReviewJeData] = useState<TransactionLine[]>([])

  // ** Used for async virtual table **
  const lastListRenderInfo = useRef<{ start: number; renderLen: number }>({
    start: -1,
    renderLen: -1
  })

  // ** Zustand hooks **
  const {
    // ** Import this if needed **
    setReviewJeData: setGlobalReviewJeData,
    reviewJeData: globalReviewJeData,
    page,
    recordsPerPage,
    setTotalDataSize,
    setTab,
    filter,
    period,
    sortField,
    sortDir,
    handleTableSort,
    filter_fields
  } = useQjeStore()

  // ** React Query hooks **
  const { getQjeSettings } = useQje()
  const {
    isLoading: isLoadingTxnGlobalSettings,
    isRefetching: isRefetchingTxnGlobalSettings
  } = useTxnGlobalSettings()
  const {
    data: qjeSettings,
    isLoading: isLoadingQjeSettings,
    isRefetching: isRefetchingQjeSettings
  }: UseQueryResult<any, any> = getQjeSettings
  const {
    data: reviewJe,
    isLoading: isLoadingReviewJe,
    isRefetching: isRefetchingReviewJe
  }: UseQueryResult<any, any> = useQuery([
    'review-je',
    filter,
    filter_fields,
    page,
    period,
    recordsPerPage,
    sortField,
    sortDir
  ])

  // ** Fixed columns for Review JE **
  let fixedColumns: TableProps<TransactionLine>['columns'] = [
    {
      title: () => <HeaderLineGroupCheckbox selectableStatus={['Unposted']} />,
      dataIndex: 'fixed_checkbox',
      width: 53,
      fixed: 'left',
      // ** Use this to enable async virtualized... **
      render: (value, record, index) =>
        [undefined].includes(value) ? (
          <VirtualSpinner />
        ) : (
          value && (
            <LineGroupCheckbox index={index} line_group={record.line_group} />
          )
        ),
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        style: { backgroundColor: record.backgroundColor },
        'data-testid': `qje-review-je-checkbox-linegroup-${record.id}`,
        'data-cy': `qje-review-je-checkbox-linegroup-${record.id}`
      })
    },
    {
      title: () => (
        <span
          data-testid='qje-review-je-history-header'
          data-cy='qje-review-je-history-header'
        />
      ),
      dataIndex: 'fixed_history',
      width: 50,
      fixed: 'left',
      // ** Use this to enable async virtual...
      render: (value, record) =>
        [undefined].includes(value) ? (
          <VirtualSpinner />
        ) : (
          value && (
            <GlobalHistoryModal
              columns={
                !isLoadingQjeSettings
                  ? [
                      ...(fixedColumnsForQjeHistory as Record<string, any>[]),
                      ...applyCustomRenderingNonAsync({
                        qjeColumns: destructureSettings({
                          settings: qjeSettings?.responseData
                        }),
                        readOnly: true
                      })!
                    ]
                  : []
              }
              reloadData={() => getQjeLogs(record?.line_group as string)}
              isQje
            />
          )
        ),
      // render: (value, record) =>
      //   record.has_history && (
      //     <GlobalHistoryModal
      //       columns={
      //         !isLoadingQjeSettings
      //           ? [
      //               ...(fixedColumnsForQjeHistory as Record<string, any>[]),
      //               ...(applyCustomRendering({
      //                 qjeColumns: destructureSettings({
      //                   settings: qjeSettings?.responseData
      //                 })
      //               })! as any)
      //             ]
      //           : []
      //       }
      //       reloadData={() => getQjeLogs(record?.line_group as string)}
      //       isQje
      //     />
      //   ),
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        style: {
          backgroundColor: record.backgroundColor,
          color: '#725bb4'
        },
        'data-testid': `qje-review-je-history-btn-${record?.line_group}`,
        'data-cy': `qje-review-je-history-btn-${record?.line_group}`
      })
    }
  ]

  let fixedColumnsForQjeHistory: TableProps<TransactionLine>['columns'] = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      width: 200,
      render: (value: number, record: any, index: number) =>
        moment(value * 1000).format('MM/DD/YYYY hh:mm a'),
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-date-${record.id}`,
        'data-cy': `qje-review-je-history-date-${record.id}`
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      render: (value: any, record: any, index: number) =>
        value || record.g_status,
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-status-${record.id}`,
        'data-cy': `qje-review-je-history-status-${record.id}`
      })
    },
    {
      title: 'Action by',
      dataIndex: 'user_name',
      width: 200,
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-user_name-${record.id}`,
        'data-cy': `qje-review-je-history-user_name-${record.id}`
      })
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      width: 200,
      onCell: (record) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-user_email-${record.id}`,
        'data-cy': `qje-review-je-history-user_email-${record.id}`
      })
    },
    {
      title: 'Resubmitted',
      dataIndex: 'g_resubmitted',
      width: 150,
      render: (value: any, record: any, index: number) =>
        value === 1 && (
          <CheckOutlined
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        ),
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-g_resubmitted-${record.id}`,
        'data-cy': `qje-review-je-history-g_resubmitted-${record.id}`
      })
    },
    {
      title: 'Resubmitted Reason',
      dataIndex: 'resubmitted_reason',
      width: 200,
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
        'data-testid': `qje-review-je-history-resubmitted_reason-${record.id}`,
        'data-cy': `qje-review-je-history-resubmitted_reason-${record.id}`
      })
    }
  ]

  const isLoadedAllData: () => boolean = () => {
    return (
      !isLoadingTxnGlobalSettings &&
      !isLoadingQjeSettings &&
      !isLoadingReviewJe &&
      !isRefetchingReviewJe &&
      !isRefetchingTxnGlobalSettings &&
      !isRefetchingQjeSettings
    )
  }

  // This is only for SORTING
  // Pagination and filters are unused; they are handled by custom components
  const handleTableChange: TableProps<TransactionLine>['onChange'] = (
    pagination,
    filters,
    { field, order }: any
  ) => {
    const sortField = field.startsWith('custom')
      ? field.replace('custom_', '')
      : field // `custom_` is appended through onListRender()
    const sortDir = order === 'ascend' ? 'asc' : 'desc'
    handleTableSort(sortField, sortDir)
  }

  // ** Used for async virtual table **
  const onListRender = useCallback(
    (listInfo: { start: number; renderLen: number }) => {
      const { start, renderLen } = listInfo
      const lastInfo = lastListRenderInfo.current
      if (start !== lastInfo.start || renderLen !== lastInfo.renderLen) {
        lastListRenderInfo.current = { start, renderLen }
        setReviewJeData((prev) => {
          const currentData = prev.slice(start, start + renderLen)
          currentData.forEach((item: any, index: any) => {
            // Handle fixed columns here...
            item.fixed_checkbox = item?.g_status === 'Unposted' || null
            item.fixed_history = item?.has_history || null

            // Handle custom columns here...
            // Columns that render plain text only...

            // Virtual table's index seems to be dynamic while scrolling, causing the checking of even/odd index to be inconsistent
            // To override this behavior, use `isEven` property for more consistent checking
            item.custom_g_debit = item.isEven
              ? parseFloat(item.g_debit || item.g_credit)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : null
            item.custom_g_credit = !item.isEven
              ? parseFloat(item.g_credit || item.g_debit)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : null
            item.custom_created_at =
              getDateTimeMomentFormat(
                item.created_at,
                getDateFormat('m/d/Y'),
                getMomentTimeFormat('h:i a')
              ) || null

            // Columns that render JSX elements...
            item.custom_transaction_number = item.transaction_number || null
            item.custom_g_threshold_support_document =
              item.g_threshold_support_document || null
            item.custom_g_reviewed = item.g_reviewed
            item.custom_g_resubmitted = item.g_resubmitted === 1 || null
            item.custom_g_is_prepaid = item.g_is_prepaid === 1 || null
          })
          const newData = JSON.parse(JSON.stringify(prev))
          return newData
        })
      }
    },
    []
  )

  // ** Used for virtualizing table **
  // Link to guide: https://codesandbox.io/s/shu-xing-biao-ge-forked-4lt6u?file=/src/index.tsx
  const vComponent = useMemo(
    () =>
      VList({
        height: 'calc(100vh - 355px)', //actual table size
        resetTopWhenDataChange: false,
        onListRender
      }),
    // eslint-disable-next-line
    [onListRender, height, globalReviewJeData]
  )

  // to adjust virtual list height that fits the table
  // if window is zoom in and out
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, [])

  useEffect(() => setTab('review-je'), [setTab])

  useEffect(() => {
    if (!isLoadingReviewJe && !isRefetchingReviewJe && reviewJe) {
      setGlobalReviewJeData(reviewJe?.responseData)
      setReviewJeData(
        (reviewJe?.responseData as TransactionLine[]).map((record, index) => {
          // Virtual table's index seems to be dynamic while scrolling, causing the checking of even/odd index to be inconsistent
          // To override this behavior, add an `isEven` property for more consistent checking
          let modifiedRecord = { ...record, isEven: index % 2 === 0 }

          // Enforce alternating rowSpan (2 then 0)
          // This will override the rowSpan being returned by BE
          modifiedRecord.rowSpan = index % 2 === 0 ? 2 : 0

          // Enforce alternating colors between line_groups
          modifiedRecord.backgroundColor =
            index % 4 === 0 || index % 4 === 1 ? '#ffffff' : '#f9f9f9'

          return modifiedRecord
        })
      )
      setTotalDataSize(Number(reviewJe?.responseHeaders['x-total-count']))
    }
  }, [
    isLoadingReviewJe,
    isRefetchingReviewJe,
    reviewJe,
    reviewJe?.responseData,
    reviewJe?.responseHeaders,
    setGlobalReviewJeData,
    setTotalDataSize
  ])

  return (
    <div data-testid='qje-review-je' data-cy='qje-review-je'>
      <Card style={{ borderTopWidth: 0 }}>
        <ActionButtonsRow />
        <div className='data-table'>
          <Table
            columns={
              isLoadedAllData()
                ? [
                    ...fixedColumns,
                    ...applyCustomRendering({
                      qjeColumns: destructureSettings({
                        settings: qjeSettings?.responseData,
                        isSortable: true
                      })
                    })!
                  ]
                : []
            }
            components={vComponent} // Docs for VList: https://github.com/crawler-django/virtuallist-antd
            data-cy='qje-review-je-table'
            data-testid='qje-review-je-table'
            dataSource={isLoadedAllData() ? reviewJeData : []}
            loading={!isLoadedAllData()}
            onChange={handleTableChange}
            pagination={false}
            rowKey='id'
            scroll={{ x: '100%', y: 'calc(100vh - 355px)' }}
            showSorterTooltip={false}
          />
          <div className='qje-pagination'>
            <QjeCustomPager />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ReviewJE
