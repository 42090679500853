/** react router imports */
import { useLocation, useNavigate } from 'react-router-dom'

/** zustand imports */
import useTxnGlobalSettingsStore from '../../../global/useTxnGlobalSettingsStore'
import useSettingsStore from '../manager/settings-store'

/** hooks imports */
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

/** util imports */
import isGappifyAdministrator from '../utils/isGappifyAdministrator'

/** constants imports */
import MENU_CONFIG from '../constants/MENU_CONFIG'
import SETTINGS_CONFIG from '../constants/SETTINGS_CONFIG'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

// ===========================================================
export default function useNavigateSettings() {
  /** react router */
  const navigate = useNavigate()
  const location = useLocation()

  /** zustand states */
  const { txnGlobalSettings }: Record<string, any> = useTxnGlobalSettingsStore()
  const setSelectedSettings = useSettingsStore(
    (state) => state.setSelectedSettings
  )
  const setSettingsDescription = useSettingsStore(
    (state) => state.setSettingsDescription
  )
  const setIsHistoryEnabled = useSettingsStore(
    (state) => state.setIsHistoryEnabled
  )
  const setHistoryColumns = useSettingsStore((state) => state.setHistoryColumns)

  /** hooks */
  const RP = useRolesAndPermissions()
  const isFlagEnabled = useIsFlagEnabled()

  const checkNavigation = (completePath: string) => {
    const currentPath = completePath || location.pathname

    // If the current path is in the allowed paths, navigate as is.
    let isAllowedPath = false
    SETTINGS_CONFIG.some((config) => {
      if (config.path === currentPath) {
        setSelectedSettings(config.label)
        setSettingsDescription(config.description)
        setIsHistoryEnabled(config.isHistoryEnabled || false)
        setHistoryColumns(config.historyColumns || [])
        isAllowedPath = true
        return true
      }
      return false
    })
    if (isAllowedPath) {
      return
    }

    // Else, run the switch case to navigate to the default path base on user permissions.
    switch (true) {
      /**
       * Conditions to redirect to Period Management url by default:
       * - User has Period Manager Settings permission
       * - edinburgh_period_management feature flag is enabled
       */
      case RP.USER_HAS_PERIOD_MANAGER_SETTINGS_PERMISSION &&
        isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management):
        navigate('/settings/period-management')
        setSelectedSettings(MENU_CONFIG.PERIOD_MANAGEMENT.LABEL)
        break
      case RP.USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION:
        navigate('/settings/user-management/users')
        setSelectedSettings(MENU_CONFIG.USER_MANAGEMENT.USERS.LABEL)
        break
      case RP.USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION:
        navigate('/settings/schedule-manager')
        setSelectedSettings(MENU_CONFIG.SCHEDULE_MANAGER.LABEL)
        break
      case RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW:
        navigate('/settings/accrual-rules')
        setSelectedSettings(MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.LABEL)
        break
      case RP.USER_HAS_LOGIN_AUDIT_TRAIL_PERMISSION:
        navigate('/settings/user-management/login-audit-trail')
        setSelectedSettings(MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.LABEL)
        break
      case isGappifyAdministrator() &&
        txnGlobalSettings['transaction.feature_flags']?.enable_new_transactions:
        navigate('/settings/txn-enable-features')
        setSelectedSettings(MENU_CONFIG.TXN_ENABLE_FEATURES.LABEL)
        break
      default:
        navigate('/error?message=Forbidden&status=403')
        break
    }
  }

  return {
    checkNavigation
  }
}
